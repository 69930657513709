<template>
  <div></div>
</template>

<script>
import Swal from "sweetalert2";
import liff from "@line/liff"
export default {
  name: "getUidLine",
  async created() {
    await liff.init(
      { liffId: "1655375613-gP14BOj4" },
      () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          this.liffGetProfile();
        }
      },
      (err) => console.log(err.code, err.message)
    );
  },
  methods: {
    liffGetProfile() {
      liff
        .getProfile()
        .then((profile) => {
          Swal.fire({
            icon: "success",
            title: "Capture",
            text: profile.userId,
            showConfirmButton: true,
          });
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style>
</style>